.background {
    display: flex;
    align-items: center;
    background-color: #196cc7;
    padding-bottom: 50px;
    height: 90vh;
}

.main-content {
    padding-top: 5rem;
    text-align: center;
    align-items: center;

}

.body-main-title h1 {
    font-size: 4rem;
    color: #fff;
    text-align: start;
}

.title-one {
    font-weight: 800;
}

.body-main-para {
    padding-top: 1rem;
    color: #fff;
    text-align: justify;
}

.main-button {
    display: flex;
    justify-content: flex-start;
    gap: 1rem;
}

.mainbanner-img img {
    width: 100%;
}

.main-button .btn-getfreedemo .btn-primary {
    background-color: rgb(255, 255, 255);
    border-radius: 50px;
    color: rgb(0, 0, 0);
    padding: 5px 20px;
    border: none;
    font-weight: 700;
    border: 2px solid #fff;

}

.main-button .btn-getfreedemo .btn-primary:hover {
    background-color: rgba(255, 255, 255, 0);
    border: 2px solid #fff;
    color: rgb(255, 255, 255);
}

.main-button .btn-contact .btn-primary {
    background-color: rgba(255, 255, 255, 0);
    border: 2px solid #fff;
    border-radius: 50px;
    color: rgb(255, 255, 255);
    padding: 5px 20px;
    font-weight: 700;
    border: 2px solid #fff;

}

.main-button .btn-contact .btn-primary:hover {
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
}

/* best Employee Section styles */

.employeeheading {
    text-align: center;
}

.heademp1 {
    font-size: 52px;
    font-weight: 400;
    color: #2474CB;
    text-transform: uppercase;
}

.heademp2 {
    font-size: 52px;
    font-weight: 800;
    color: #1F2242;
    text-transform: uppercase;
    line-height: 25px;
}

.bestemployee {
    padding-top: 50px;
    padding-bottom: 50px;
    background-color: #F3F9FE;
}

/* best Employee card1 styles */

.card1main1 {
    background: #FFFFFF;
    border: 5px solid rgba(250, 114, 120, 0.15);
    box-shadow: 0px 0px 31px 1px rgba(255, 248, 248, 0.79);
    border-radius: 14px;
    padding: 20px;

}

.logocard1 {
    width: 100px;
    height: 100px;
    background: linear-gradient(154.49deg, #FF858A 6.61%, #F04148 89.72%);
    box-shadow: 0px 8px 22px -2px rgba(248, 87, 94, 0.3);
    border-radius: 18px;
}

.logocard1 img {
    width: 70%;
}

.logocard1 {
    display: flex;
    justify-content: center;
    align-items: center;
}

.empcardhead1 p {
    padding-left: 8px;
}

.empcardhead1 h1 {
    padding-left: 8px;
    font-weight: 700;
    font-size: 28px;
    color: #F8686E;
}

/* best Employee card2 styles */

.card1main2 {
    background: #FFFFFF;
    border: 5px solid rgba(255, 195, 99, 0.18);
    box-shadow: 0px 0px 31px 1px rgba(255, 248, 248, 0.79);
    border-radius: 14px;
    padding: 20px;

}

.logocard2 {
    width: 100px;
    height: 100px;
    background: linear-gradient(154.49deg, #FFD085 6.61%, #FFAF2E 89.72%);
    box-shadow: 0px 10px 22px -2px rgba(250, 186, 84, 0.3);
    border-radius: 18px;
}

.logocard2 img {
    width: 70%;
}

.logocard2 {
    display: flex;
    justify-content: center;
    align-items: center;
}

.empcardhead2 p {
    padding-left: 8px;
}

.empcardhead2 h1 {
    padding-left: 8px;
    font-weight: 700;
    font-size: 28px;
    color: #FFB135;
}

/* best Employee card3 styles */

.card1main3 {
    background: #FFFFFF;
    border: 5px solid rgba(67, 145, 250, 0.18);
    box-shadow: 0px 0px 31px 1px rgba(255, 248, 248, 0.79);
    border-radius: 14px;
    padding: 20px;

}

.logocard3 {
    width: 100px;
    height: 100px;
    background: linear-gradient(154.49deg, #5CA1FE 6.61%, #217BF4 89.72%);
    box-shadow: 0px 10px 22px -2px rgba(109, 171, 255, 0.36);
    border-radius: 18px;
}

.logocard3 img {
    width: 70%;
}

.logocard3 {
    display: flex;
    justify-content: center;
    align-items: center;
}

.empcardhead3 p {
    padding-left: 8px;
}

.empcardhead3 h1 {
    padding-left: 8px;
    font-weight: 700;
    font-size: 28px;
    color: #277FF5;
}

/* best Employee card4 styles */

.card1main4 {
    background: #FFFFFF;
    border: 5px solid rgba(250, 114, 120, 0.15);
    box-shadow: 0px 0px 31px 1px rgba(255, 248, 248, 0.79);
    border-radius: 14px;
    padding: 20px;

}

.logocard4 {
    width: 100px;
    height: 100px;
    background: linear-gradient(154.49deg, #FF858A 6.61%, #F04148 89.72%);
    box-shadow: 0px 8px 22px -2px rgba(248, 87, 94, 0.3);
    border-radius: 18px;
}

.logocard4 img {
    width: 70%;
}

.logocard4 {
    display: flex;
    justify-content: center;
    align-items: center;
}

.empcardhead4 p {
    padding-left: 8px;
}

.empcardhead4 h1 {
    padding-left: 8px;
    font-weight: 700;
    font-size: 28px;
    color: #F45359;
}

.card1main1,
.card1main2,
.card1main3,
.card1main4 {
    margin-top: 20px;
}

/* Letsget Started Section */

.letsstarted {
    padding-top: 50px;
    padding-bottom: 50px;
}

.imageforlrtsget img {
    width: 100%;
}

.quoteimg img {
    width: 20%;
}

.titlesection {
    position: relative;
}

.quoteimg2 {
    right: 212px;
    top: 104px;
    position: absolute;
    width: 100%;
    justify-content: flex-end;
    display: flex;
}

.quoteimg2 img {
    width: 12%;
}

.titlesection h1 {
    font-size: 72px;
    font-weight: bold
}

.titlesection p {
    font-size: 22px;
    font-weight: 500;
    text-align: justify;
}

.howitswork {
    padding-top: 50px;
    padding-bottom: 50px;
    background-color: #F3F9FE;
}

.alignhowitswork {
    align-items: center;
}

.iconfeature {
    width: 90px;
    height: 90px;
    border-radius: 50%;
}

.features {
    padding-top: 50px;
}

.iconfeature img {
    width: 40%;
}

.iconfeature {
    display: flex;
    justify-content: center;
    align-items: center;
}

.featuretitle h1 {
    font-weight: 800;
    font-size: 32px;
    padding-bottom: 10px;
}

.bg-red {
    background-color: #F04148;
}
.bg-blue {
    background-color: #217BF4;
}
.bg-orange {
    background-color: #FFAF2E;
}
.mainfeaturecard{
    margin-top: 1rem;
}
.mainheadfeature h1{
    font-size: 72px;
    font-weight: 800;
    padding-bottom: 50px;
}
.footerbanner img{
    width: 100%;
}

.footer{
    background-color: #196cc7;
    padding: 100px 0px;
}
.contactudtitle {
    text-align: center;
}

.contactudtitle h1 {
    font-size: 72px;
    font-weight: bold;
    color: #ffffff;
    padding-bottom: 60px;
}

.form-control {
    border: 1px solid transparent !important;
    border-bottom: 1px solid #bce7ff !important;
    width: 100% !important;
    color: #fff;
    border-radius: 0 !important;
    background-color: transparent !important;
    height: 50px;
    margin-bottom: 2rem;
    font-family: 'Raleway', sans-serif;

}
.form-control:focus {
    background-color: rgba(255, 255, 255, 0) !important;
    outline: 0 !important;
    box-shadow: 0 0 0 0.25rem rgba(13, 109, 253, 0) !important;
}
.contact-freedemo-btn{
    width: 100%;
    color: #0A093D;
    height: 50px;
    border-radius: 100px !important;
    font-weight: 700 !important;
    background-color: #ffffff !important;
    border: 2px solid #fff;
}
.contact-freedemo-btn:hover{
    width: 100%;
    color: #ffffff;
    height: 50px;
    border-radius: 100px !important;
    font-weight: 700 !important;
    background-color: #ffffff00 !important;
    border: 2px solid #fff;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.titile-contact{
    padding: 10px 50px;
    color: #fff;
}
.titile-contact p{
    font-size: 24px;
    color: #fff;
}
.titile-contact1{
    padding: 10px 50px;
}
.iconc img{
    width: 80%;
}
.adresstext{
    font-size: 20px;
    line-height: 24px;
    color: #fff;
}
.adressbar{
    padding-bottom: 30px;
}
.text-allignment img{
    width: 20%;
}
input::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.644) !important;
}

input:-moz-placeholder {
    color: rgba(255, 255, 255, 0.644) !important;
}

textarea::-webkit-input-placeholder {
    color: #fff !important;
    }



@media (max-width: 991.98px) {
    .body-main-title h1 {
        font-size: 2.5rem;
        color: #fff;
        text-align: center;
    }

    .body-main-title .title-two {
        font-size: 2rem;
    }

    .body-main-title {
        border-bottom: 1px solid rgba(255, 255, 255, 0.178);
        radius: 50px;
    }

    .heademp1 {
        font-size: 24px;
    }

    .heademp2 {
        font-size: 32px;
    }

    .cardcol {
        display: block;
    }

    .main-button {
        justify-content: center;
    }

    .card1main1,
    .card1main2,
    .card1main3,
    .card1main4 {
        margin-top: 10px;
    }

    .quoteimg2 {
        right: 50px;
        top: 120px;
    }

}

@media (max-width: 768px) {

    a.logomain.navbar-brand {
        margin-right: 0;
        width: 80% !important;
    }

    .main-button {
        width: 100%;
        display: block;
    }

    .main-button .btn-contact .btn-primary,
    .main-button .btn-getfreedemo .btn-primary {
        width: 70%;
        margin-top: 20px;
    }
}