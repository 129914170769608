.headermain{
    background-color: #196cc7;
}
.logomain img{
    width: 50%;
}
.social-icons{
    display: flex;
    columns: white;
    font-size: 18px;
    align-items: center;
}
.social-icons .facebook{
    padding: 0 5px;
    color: #fff;
}
a.logomain.navbar-brand {
    margin-right: 0;
    width: 20%;
}
.nav-link:focus, .nav-link:hover{
    color: #fff !important;
    border-bottom: 3px solid white;
}
.navbar-nav .nav-link.active, .navbar-nav .show>.nav-link{
    color: #fff !important;
}
.navbar-expand-lg .navbar-collapse {
    justify-content: space-between;
}
.navbar-nav a {
    color: #fff;
}
button.btn-get-btn.btn.btn-primary {
    background-color: #fff;
    border-radius: 50px;
    color: black;
    padding: 5px 20px;
    border: none;
    font-weight: 700;
}
.harmburger{
    color: #fff;
    border: none;
    font-size: 1.5rem;
    justify-content: flex-end;
    display: flex;
}
.harmburger:focus{
    border: none !important;
    outline: none !important;
    box-shadow: 0 0 0 !important;
}
.navbar-toggler{
    padding: 0;
    margin: 0;
}

